@media (--mobile-viewport) {
    .hide-on-mobile {
        display: none !important;
    }
}

@media (--desktop-viewport) {
    .show-on-mobile {
        display: none !important;
    }
}
